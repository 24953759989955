@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700");

@tailwind base;

@tailwind components;

@tailwind utilities;
body {
  font-family: "Montserrat", sans-serif;
}

#hero-area {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url("../images/background.jpg") no-repeat;
  background-size: cover;
}
#hero-area .ping-slow {
  animation: ping 3s cubic-bezier(0, 0, 0.1, 0.5) infinite;

  @keyframes ping {
    75%,
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
}

/*  */

#hero-area .content {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

#about .content {
  @apply bg-gray-200;
  // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),
  //   url("../images/South-Sudan-Famine-Investor-in-the-Family.jpeg") no-repeat;
  background: url("../images/hero.jpg") no-repeat;
  padding: 20px;
  border-radius: 10px;
}
#features .contenta {
  @apply bg-gray-200;
  // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),
  //   url("../images/South-Sudan-Famine-Investor-in-the-Family.jpeg") no-repeat;
  background: url("../images/movementss.jpg") no-repeat;
  padding: 20px;
  border-radius: 10px;
}
#features .contentb {
  @apply bg-gray-200;
  // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),
  //   url("../images/South-Sudan-Famine-Investor-in-the-Family.jpeg") no-repeat;
  background: url("../images/maintenacee.jpg") no-repeat;
  padding: 20px;
  border-radius: 10px;
}
#features .contentc {
  @apply bg-gray-200;
  // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),
  //   url("../images/South-Sudan-Famine-Investor-in-the-Family.jpeg") no-repeat;
  background: url("../images/Invoicing.jpg") no-repeat;
  padding: 20px;
  border-radius: 10px;
}
#features .contentd {
  @apply bg-gray-200;
  // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),
  //   url("../images/South-Sudan-Famine-Investor-in-the-Family.jpeg") no-repeat;
  background: url("../images/Trips.jpg") no-repeat;
  padding: 20px;
  border-radius: 10px;
}

p {
  @apply text-gray-500;
}
.container {
  @apply mx-auto px-5;
}
.btn {
  @apply text-white bg-teal-500 px-10 py-3 rounded-full duration-300;
}
.btn:hover {
  @apply bg-teal-500;
}
.sticky {
  @apply fixed bg-white shadow;
}
.collapse.show {
  @apply top-16 block;
}
.toggler-icon {
  @apply block bg-gray-400 relative duration-300;
  height: 2px;
  width: 30px;
  margin: 6px 0;
}
.active > .toggler-icon:nth-child(1) {
  @apply transform rotate-45;
  top: 7px;
}
.active > .toggler-icon:nth-child(2) {
  @apply opacity-0;
}
.active > .toggler-icon:nth-child(3) {
  top: -8px;
  transform: rotate(135deg);
}
.page-scroll {
  @apply block px-4 py-2 mx-1 font-light rounded-md capitalize  text-gray-400 duration-300;
}
.page-scroll.active,
.page-scroll:hover {
  @apply text-teal-500 font-medium  bg-opacity-25;
}
.section-heading {
  @apply text-3xl text-gray-700 font-bold tracking-wide;
}

.icon {
  @apply mb-4 text-teal-500;
}

.service-title {
  @apply text-gray-800 font-semibold text-lg block mb-3;
}

.feature-title {
  @apply text-gray-800 font-medium block mb-3;
}
.team-item {
  @apply m-3 text-center bg-white shadow duration-300;
}
.team-item:hover {
  @apply shadow-lg;
}
.team-overlay {
  @apply w-full h-full flex items-center justify-center absolute top-0 left-0 bg-gray-200 duration-300 opacity-0;
}
.team-overlay:hover {
  @apply opacity-100;
}

.social-link {
  @apply flex justify-center items-center w-10 h-10 bg-teal-500 rounded text-lg text-white duration-300;
}
.team-name {
  @apply text-gray-800 font-bold uppercase text-lg my-2;
}

.client-logo {
  @apply opacity-50 duration-300;
}
.client-logo:hover {
  @apply opacity-100;
}
.tns-nav {
  @apply absolute w-full bottom-0 -mb-16 text-center;
}
.tns-nav button {
  @apply w-3 h-3 rounded-full bg-white opacity-50 mx-1 my-6 duration-300;
}
.tns-nav > .tns-nav-active,
.tns-nav button:hover {
  @apply opacity-100;
}
.tns-nav button:focus {
  @apply outline-none;
}

.form-input {
  @apply w-full mb-5 bg-white border border-gray-500 px-4 py-2 duration-300 outline-none;
}
.form-input:focus {
  @apply border-teal-500;
}
.contact-icon {
  @apply w-10 h-10 flex items-center justify-center bg-teal-500 text-xl text-white rounded-full;
}

.footer-links {
  @apply leading-9 text-gray-100 duration-300;
}
.footer-links:hover {
  @apply text-teal-500;
}
.footer-icon {
  @apply flex justify-center items-center w-8 h-8 bg-white rounded-full text-sm text-gray-700 duration-300;
}
.footer-icon:hover {
  @apply text-white;
}
